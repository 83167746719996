import { createStore } from 'vuex'
import account from "@/store/modules/account";

export default createStore({
  state: {
    menuList: null,
    token:localStorage.token,
    projectScene:null,    //  项目场景 0:开发 1:PC端 2:管理端
    servertypes:null,     //  服务商开通的业务模式
  },
  getters: {
    menuList: state=>{
      return state.menuList
    },
    token: state=>{
      return state.token
    },
    projectScene: state=>{
      return state.projectScene
    },
    servertypes: state=>{
      return state.servertypes
    }
  },
  mutations: {
    setMenuList(state, menuList) {
      state.menuList = menuList
    },
    delMenuList(state){
      state.menuList = []
    },

    setToken(state, token){
      state.token = token
      localStorage.token = token
    },
    delToken(state){
      state.token = ''
      localStorage.removeItem('token')
    },

    setProjectScene(state, projectScene){
      state.projectScene = projectScene
      localStorage.projectScene = projectScene
    },
    delProjectScene(state){
      state.projectScene = null
      localStorage.projectScene = null
    },
    setServertypes(state, servertypes){
      state.servertypes = servertypes
    },
    delServertypes(state){
      state.servertypes = null
    }
  },
  actions: {
    setMenuList(context, menuList){
      context.commit('setMenuList', menuList)
    },
    delMenuList(context){
      context.commit('delMenuList')
    },

    setToken(context, token){
      context.commit('setToken', token)
    },
    delToken(context){
      context.commit('delToken')
    },

    setProjectScene(context, projectScene) {
      context.commit('setProjectScene', projectScene)
    },
    delProjectScene(context) {
      context.commit('delProjectScene')
    },
    setServertypes(context, servertypes){
      context.commit('setServertypes', servertypes)
    },
    delServertypes(context){
      context.commit('setServertypes')
    },
  },
  modules: {
    account
  }
})
