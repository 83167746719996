import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '@/views/login/index.vue'
import AccountView from '@/views/AccountManage/index.vue'
import accountRouters from '@/router/account'

import mainRouters from '@/router/main'
import serviceRouters from '@/router/service'
import priceRouters from '@/router/price'
import orderRouters from '@/router/order'
import userRouters from '@/router/user'
import financeRouters from '@/router/finance'
import baseRouters from '@/router/base'
import organizationRouters from '@/router/organization'
import applet from '@/router/applet'
import system from '@/router/system'
import officialWebsite from '@/router/officialWebsite'
import operationRouters from '@/router/operation'
import marketOperation from '@/router/marketOperation'

const routes = [
  {
    path: '/',
    name:'login',
    component: LoginView
  },
  {
    path: '/login',
    component: LoginView
  },
  {
    path:'/account',
    component: AccountView,
    children: accountRouters()
  },
  {
    path: '/404',
    name:'404',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Temp.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    children: [
      mainRouters(),
      serviceRouters(),
      priceRouters(),
      orderRouters(),
      financeRouters(),
      organizationRouters(),
      userRouters(),
      baseRouters(),
      applet(),
      system(),
      officialWebsite(),
      operationRouters(),
      marketOperation()
    ]
  }
]

//  本地所有的页面，需要配合接口返回的数据生成页面
export function getAsyncRoutes(){
  return {
    path: '/home',
    name: 'home',
    component: HomeView,
    children: [
      serviceRouters(),
      priceRouters(),
      orderRouters(),
      userRouters(),
    ]
  }
}

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

//  挂载路由导航守卫
router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  //  from 代表从哪个路径跳转过来
  //  next 是一个函数，表示放行
  if (to.path === '/login') return next()
  const tokenStr = window.sessionStorage.getItem('token')
  //  过滤掉注册页面，注册页面不需要token信息即可访问
  if (!tokenStr && !to.path.includes('/account/')) return next('/login')
  next()
})

export default router
