//  小程序相关
function appletRouters() {
    return {
        "title": "小程序管理",
        "path": "/AppletManage",
        "role": [0],
        "children": [
            {
                "title": "图标配置",
                "path": "/AppletManage/iconManage",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/AppletManage/iconManage.vue')
            },
            {
                "title": "帮助中心",
                "path": "/AppletManage/helpCenter",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/AppletManage/helpCenter.vue')
            },
            {
                "title": "资讯中心",
                "path": "/AppletManage/newsCenter",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/AppletManage/newsCenter.vue')
            },
            {
                "title": "小程序规则中心",
                "path": "/AppletManage/ruleCenter",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/AppletManage/ruleCenter.vue')
            },
            {
                "title": "首页轮播图片列表",
                "path": "/AppletManage/bannerManage",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/AppletManage/bannerManage.vue')
            },
            {
                "title": "首页寄件业务服务方式",
                "path": "/AppletManage/serviceTypeManage",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/AppletManage/serviceTypeManage.vue')
            },
            {
                "title": "图片配置管理",
                "path": "/AppletManage/pictureManage",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/AppletManage/pictureManage.vue')
            },
            {
                "title": "海报配置管理",
                "path": "/AppletManage/posterManage",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/AppletManage/posterManage.vue')
            },
        ]
    }
}

export default appletRouters;
