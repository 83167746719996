// 用户登录(获取token)
export const Home_SubmitLogin = "/Base_Manage/Home/SubmitLogin"
// 手机登录(获取token)
export const Home_PhoneLogin = "/Base_Manage/Home/PhoneLogin"
// 微信登录(获取token)
export const Home_WeChatLogin = "/Base_Manage/Home/WeChatLogin"
// 
export const Home_ChangePwd = "/Base_Manage/Home/ChangePwd"
// 
export const Home_GetOperatorInfo = "/Base_Manage/Home/GetOperatorInfo"
// 
export const Home_GetOperatorMenuList = "/Base_Manage/Home/GetOperatorMenuList"
