//  市场运营
function marketOperationRouters() {
    return {
        "title": "运营管理",
        "path": "/MarketOperationManage",
        "role": [0],
        "children": [
            {
                "title": "推荐管理",
                "path": "/MarketOperationManage/RecommendManage",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/MarketOperationManage/RecommendManage.vue')
            },
        ],
    }
}

export default marketOperationRouters;
