// 数据查询通用接口支持自定义参数数量(3)
export const sys_gt_service_settle_GetDataList = "/Business/sys_gt_service_settle/GetDataList"
// 数据查询通用接口支持自定义参数数量(3)
export const sys_gt_service_settle_GetDataListOneItemModel = "/Business/sys_gt_service_settle/GetDataListOneItemModel"
// 保存入驻申请
export const sys_gt_service_settle_SaveData = "/Business/sys_gt_service_settle/SaveData"
// 删除
export const sys_gt_service_settle_DeleteData = "/Business/sys_gt_service_settle/DeleteData"
// 服务商入住信息审核
export const sys_gt_service_settle_CheckData = "/Business/sys_gt_service_settle/CheckData"
// 
export const sys_gt_service_settle_GetServerRole = "/Business/sys_gt_service_settle/GetServerRole"
// 
export const sys_gt_service_settle_GetServerType = "/Business/sys_gt_service_settle/GetServerType"
// 入驻支付
export const sys_gt_service_settle_PaymentApply = "/Business/sys_gt_service_settle/PaymentApply"
// 支付状态查询
export const sys_gt_service_settle_ApplyPaymentSelect = "/Business/sys_gt_service_settle/ApplyPaymentSelect"
// 入驻续费支付
export const sys_gt_service_settle_RenewPaymentApply = "/Business/sys_gt_service_settle/RenewPaymentApply"
// 续费支付状态查询
export const sys_gt_service_settle_RenewApplyPaymentSelect = "/Business/sys_gt_service_settle/RenewApplyPaymentSelect"
