// 根据坐标获取2地之间的距离(单位 米)
export const sys_tool_GetCalculateDistance = "/Business/sys_tool/GetCalculateDistance"
// 获取验证码
export const sys_tool_GetSmsVerificationCode = "/Business/sys_tool/GetSmsVerificationCode"
// 获取地图返回的信息
export const sys_tool_Geo = "/Business/sys_tool/Geo"
// 发起微信支付
export const sys_tool_wxpayment = "/Business/sys_tool/wxpayment"
// 企查查
export const sys_tool_GetQXXServiceInfo = "/Business/sys_tool/GetQXXServiceInfo"
