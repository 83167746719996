import router from './index';
// import store from '../store'

// import {dynaLoadRouters} from '../utils/router'
//
// function resetTokenAndClearUser(){
//     store.dispatch('delToken')
// }

//  是否有菜单数据
// let hasMenus = false;
router.beforeEach((to, from, next) => {
    next();
    return
    // if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
    // else next()
    // console.log('store.state.token:', store.state.token)
    // if(store.state.token){
    //     if(hasMenus){
    //         next();
    //     } else {
    //         try {
    //             const mainRoute = dynaLoadRouters();
    //             console.log('mainRoute:', mainRoute)
    //             router.addRoutes(mainRoute)
    //             const oldRoutes = router.options.routes
    //             oldRoutes.unshift(mainRoute)
    //             router.options.routes = oldRoutes
    //
    //             console.log('....注册的路由:', router.options.routes)
    //
    //             hasMenus = true
    //             next()
    //         }catch (error){
    //             resetTokenAndClearUser()
    //             next(`/login?redirect=${to.path}`)
    //         }
    //     }
    // } else {
    //     hasMenus = false
    //     if(to.path === '/'){
    //         next()
    //     }else {
    //         next(`/?redirect=${to.path}`)
    //     }
    // }
})
