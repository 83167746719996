// 数据查询通用接口支持自定义参数数量(3)
export const sys_gt_googtypes_GetDataAllTreeList = "/Business/sys_gt_googtypes/GetDataAllTreeList"
// 通过内容查询品类返回1级数据
export const sys_gt_googtypes_GetDataAllTreeListForText = "/Business/sys_gt_googtypes/GetDataAllTreeListForText"
// 通过内容查询品类返回3级数据
export const sys_gt_googtypes_GetDataAllTreeListForText2 = "/Business/sys_gt_googtypes/GetDataAllTreeListForText2"
// 数据查询通用接口支持自定义参数数量(3)
export const sys_gt_googtypes_GetDataList = "/Business/sys_gt_googtypes/GetDataList"
// 新增
export const sys_gt_googtypes_AddData = "/Business/sys_gt_googtypes/AddData"
// 修改
export const sys_gt_googtypes_UpdaData = "/Business/sys_gt_googtypes/UpdaData"
// 删除
export const sys_gt_googtypes_DeleteData = "/Business/sys_gt_googtypes/DeleteData"
