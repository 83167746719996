<template>
  <a-modal v-model:open="visible" :footer="null" :maskClosable="false">
    <div style="width: 80%; margin-left: 10%">
      <div style="text-align: center; font-size: 18px; margin: 30px 0;">恭喜您入驻成功，请设置您的企业相关信息</div>

      <a-steps :current="activeKey" labelPlacement="vertical" :items="[{title: '重置登录密码',},{title: '设置支付密码',}]"></a-steps>

      <div v-if="activeKey == 0" style="margin-top: 30px;">
        <div style="display: flex; align-items: center; border: 1px solid #f0f0f0; padding: 5px 10px">
          <div style="width: 80px;">登录密码</div>
          <a-input-password :bordered="false" placeholder="建议使用两种或者两种以上字符组合" />
        </div>
        <div style="display: flex; align-items: center; margin-top: 20px; border: 1px solid #f0f0f0; padding: 5px 10px">
          <div style="width: 80px;">确认密码</div>
          <a-input-password :bordered="false" placeholder="请再次输入密码" />
        </div>
      </div>
      <div v-else style="margin-top: 30px;">
        <div style="display: flex; align-items: center; border: 1px solid #f0f0f0; padding: 5px 10px">
          <div style="width: 80px;">支付密码</div>
          <a-input-password :bordered="false" placeholder="建议使用两种或者两种以上字符组合" />
        </div>
        <div style="display: flex; align-items: center; margin-top: 20px; border: 1px solid #f0f0f0; padding: 5px 10px">
          <div style="width: 80px;">确认密码</div>
          <a-input-password :bordered="false" placeholder="请再次输入密码" />
        </div>
      </div>

      <div style="margin: 30px 0;">
        <a-button style="width: 100%" type="primary" @click="onSubmit">{{activeKey == 0 ? '下一步' : '提交'}}</a-button>
      </div>
    </div>

  </a-modal>
</template>

<script>
export default {
  name: "PopupPasswordEdit",  //  密码设置
  data() {
    return {
      visible: false,
      activeKey:0,
    }
  },
  methods:{
    onSubmit(){
      if(this.activeKey == 0){
        this.activeKey += 1
      }else if(this.activeKey == 1){
        //
      }
    }
  }
}
</script>

<style scoped>

</style>
