import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Antd from 'ant-design-vue'
// import 'ant-design-vue/dist/antd.less'

import './router/permission'


const app = createApp(App)
app.use(Antd)
app.use(store).use(router).mount('#app')

import {get, post} from "@/utils/request";
app.config.globalProperties.$get = get
app.config.globalProperties.$post = post

import VueCookies from 'vue-cookies'
app.use(VueCookies)
