<template>
  <div style="background-color: white; height: 100vh">
    <a-layout>
      <a-layout-header class="header">
        <div class="logo">
          <a-avatar :size="44" style="margin-right: 5px" :src="logo"/>
          <span class="title">携航CarryGlobal</span>
        </div>
      </a-layout-header>
      <a-layout-content class="content">
        <router-view/>
      </a-layout-content>
    </a-layout>
  </div>
</template>

<script>
export default {
  name: 'indexView',
  data() {
    return {
      logo:require('@/assets/logo.png'),
    }
  },
}
</script>

<style scoped>
.header{
  height: 74px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 1px 2px 13px 0px rgba(0,0,0,0.12);
  display: flex;
  align-items: center;
  z-index: 101;
}
.content{
  height: 100%;
  width: 100%;
  background-color: white;
}
.title{
  font-size: 18px;
  font-weight: bold;
}
</style>
