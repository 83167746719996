//  报价相关
function priceRouters() {
    return {
        "title": "报价管理",
        "path": "/PriceManage",
        "children": [
            {
                "title": "国内出港报价管理",
                "path": "/PriceManage/Admin/DomesticDeparture",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/Admin/DomesticDeparture.vue')
            },
            {
                "title": "国内出港报价管理详情",
                "path": "/PriceManage/Admin/DomesticDepartureDetail",
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/Admin/DomesticDepartureDetail.vue')
            },
            {
                "title": "上门取件报价管理",
                "path": "/PriceManage/Admin/PickupManage",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/Admin/PickupManage.vue')
            },
            {
                "title": "上门取件报价管理详情",
                "path": "/PriceManage/Admin/PickupManageDetail",
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/Admin/PickupManageDetail.vue')
            },
            {
                "title": "国际/港澳台出港报价管理",
                "path": "/PriceManage/Admin/InternationalDeparture",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/Admin/InternationalDeparture.vue')
            },
            {
                "title": "到港标派报价管理",
                "path": "/PriceManage/Admin/ArrivalDelivery",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/Admin/ArrivalDelivery.vue')
            },
            {
                "title": "到港标派报价管理详情",
                "path": "/PriceManage/Admin/ArrivalDeliveryDetail",
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/Admin/ArrivalDeliveryDetail.vue')
            },
            {
                "title": "到港专车报价管理",
                "path": "/PriceManage/Admin/ArrivalSpecialCar",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/Admin/ArrivalSpecialCar.vue')
            },
            {
                "title": "到港代提报价管理",
                "path": "/PriceManage/Admin/ProxyDeliveryManage",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/Admin/ProxyDeliveryManage.vue')
            },
            {
                "title": "到港卡航报价管理",
                "path": "/PriceManage/Admin/ArrivalTruck",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/Admin/ArrivalTruck.vue')
            },
            {
                "title": "冷链物流报价管理",
                "path": "/PriceManage/Admin/ColdChainLogistics",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/Admin/ColdChainLogistics.vue')
            },
            {
                "title": "优选卡航报价管理",
                "path": "/PriceManage/Admin/PreferredTruck",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/Admin/PreferredTruck.vue')
            },
            {
                "title": "宠物空运报价管理",
                "path": "/PriceManage/Admin/PetAirTransport",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/Admin/PetAirTransport.vue')
            },
            {
                "title": "服务商网点信息管理",
                "path": "/PriceManage/Admin/ServiceOutlets",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/Admin/ServiceOutlets.vue')
            },
            {
                "title": "国内出港",
                "path": "/PriceManage/DepartureList",
                "isActivate": true,
                "role": [1],
                "serviceType":1,
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/DepartureList.vue')
            },
            {
                "title": "新增国内出港",
                "path": "/PriceManage/DepartureFrom",
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/DepartureFrom.vue')
            },
            {
                "title": "国内出港详情",
                "path": "/PriceManage/DepartureDetail",
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/DepartureDetail.vue')
            },
            {
                "title": "上门取件",
                "path": "/PriceManage/PickupList",
                "isActivate": true,
                "role": [1],
                "serviceType":1,
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/PickupList.vue')
            },
            {
                "title": "新增上门取件",
                "path": "/PriceManage/PickupFrom",
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/PickupFrom.vue')
            },
            {
                "title": "上门取件详情",
                "path": "/PriceManage/PickupDetail",
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/PickupDetail.vue')
            },
            {
                "title": "到港标派",
                "path": "/PriceManage/ArrivalDeliveryList",
                "isActivate": true,
                "role": [1],
                "serviceType":3,
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/ArrivalDeliveryList.vue')
            },
            {
                "title": "新增到港标派",
                "path": "/PriceManage/ArrivalDeliveryFrom",
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/ArrivalDeliveryFrom.vue')
            },
            {
                "title": "到港标派详情",
                "path": "/PriceManage/ArrivalDeliveryDetail",
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/ArrivalDeliveryDetail.vue')
            },
            {
                "title": "到港专车",
                "path": "/PriceManage/SpecialCarList",
                "isActivate": true,
                "role": [1],
                "serviceType":4,
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/SpecialCarList.vue')
            },
            {
                "title": "新增到港专车",
                "path": "/PriceManage/SpecialCarFrom",
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/SpecialCarFrom.vue')
            },
            {
                "title": "到港代提",
                "path": "/PriceManage/ProxyDeliveryList",
                "isActivate": true,
                "role": [1],
                "serviceType":5,
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/ProxyDeliveryList.vue')
            },
            {
                "title": "新增到港代提",
                "path": "/PriceManage/ProxyDeliveryFrom",
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/ProxyDeliveryFrom.vue')
            },
            {
                "title": "到港代提详情",
                "path": "/PriceManage/ProxyDeliveryDetail",
                component: () => import(/* webpackChunkName: "about" */ '@/views/PriceManage/ProxyDeliveryDetail.vue')
            }
        ],
        'isService':1,
    }
}

export default priceRouters;
