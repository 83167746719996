//  操作中心相关
function operationRouters() {
    return {
        "title": "操作中心",
        "path": "/OperationManage",
        "role": [1],
        "children": [
            {
                "title": "国内出港订单",
                "path": "/OperationManage/DomesticDepartureOrderList",
                "isActivate": true,
                "role": [1],
                "serviceType":1,
                component: () => import(/* webpackChunkName: "about" */ '@/views/OperationManage/DomesticDepartureOrderList.vue')
            },
            {
                "title": "到港标派订单",
                "path": "/OperationManage/ArrivalOrderList",
                "isActivate": true,
                "role": [1],
                "serviceType":3,
                component: () => import(/* webpackChunkName: "about" */ '@/views/OperationManage/ArrivalOrderList.vue')
            },
        ],
        'isService':1,
    }
}

export default operationRouters;
