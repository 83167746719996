//  订单相关
function orderRouters() {
    return {
        "title": "订单管理",
        "path": "/OrderManage",
        "children": [
            {
                "title": "国内空运订单管理",
                "path": "/OrderManage/Admin/DomesticAirTransport",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/OrderManage/Admin/DomesticAirTransport.vue')
            },
            {
                "title": "国际/港澳台空运订单管理",
                "path": "/OrderManage/Admin/InternationalAirTransport",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/OrderManage/Admin/InternationalAirTransport.vue')
            },
            {
                "title": "拼单业务订单管理",
                "path": "/OrderManage/Admin/CollaboratingBusiness",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/OrderManage/Admin/CollaboratingBusiness.vue')
            },
            {
                "title": "到港业务订单管理",
                "path": "/OrderManage/Admin/ArrivalBusiness",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/OrderManage/Admin/ArrivalBusiness.vue')
            },
            {
                "title": "优选卡航订单管理",
                "path": "/OrderManage/Admin/PreferredTruck",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/OrderManage/Admin/PreferredTruck.vue')
            },
            {
                "title": "冷链物流订单管理",
                "path": "/OrderManage/Admin/ColdChainLogistics",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/OrderManage/Admin/ColdChainLogistics.vue')
            },
            {
                "title": "宠物空运订单管理",
                "path": "/OrderManage/Admin/PetAirTransport",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/OrderManage/Admin/PetAirTransport.vue')
            },
            // {
            //     "title": "订单列表",
            //     "path": "/OrderManage/OrderList",
            //     "role": [1],
            //     component: () => import(/* webpackChunkName: "about" */ '@/views/OrderManage/OrderList.vue')
            // },
            {
                "title": "国内出港订单管理",
                "path": "/OrderManage/DomesticDepartureOrderList",
                "isActivate": true,
                "role": [1],
                "serviceType":1,
                component: () => import(/* webpackChunkName: "about" */ '@/views/OrderManage/DomesticDepartureOrderList.vue')
            },
            {
                "title": "到港标派订单管理",
                "path": "/OrderManage/ArrivalOrderList",
                "isActivate": true,
                "role": [1],
                "serviceType":3,
                component: () => import(/* webpackChunkName: "about" */ '@/views/OrderManage/ArrivalOrderList.vue')
            },
        ],
        'isService':1,
    }
}

export default orderRouters;
