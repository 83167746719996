<template>
  <div style="position: relative; height: 100%; width: 100%;">
    <div class="admin_content" v-if="projectScene == 2">
      <!--后台登录页面-->
      <div style="height: 100%; display: flex; align-items: center; justify-content: right">
        <div class="admin_login_box">
          <div style="margin: 45px; font-size: 15px;">
            <div style="margin-top: 40px; color: #3875F6; margin-bottom: 5px;">携航全球 一触即达</div>
            <div style="font-size: 27px; font-weight: bold;">携航CarryGlobal</div>
            <div style="margin-top: 30px; margin-bottom: 15px;">账号密码登录</div>
            <a-form :model="passwordFormInfo" autocomplete="off" @finish="onFinish">
              <a-form-item name="username" :rules="[{ required: true, message: '请输入账户名'}]">
                <a-input v-model:value="passwordFormInfo.username" placeholder="请输入账户名">
                  <template #prefix><UserOutlined/></template>
                </a-input>
              </a-form-item>
              <a-form-item name="password" :rules="[{ required: true, message: '请输入密码' }]">
                <a-input-password v-model:value="passwordFormInfo.password" placeholder="请输入密码">
                  <template #prefix><LockOutlined/></template>
                </a-input-password>
              </a-form-item>
              <a-form-item name="remember">
                <div style="display: flex; justify-content: space-between; align-items: center;">
                  <a-checkbox v-model:checked="passwordFormInfo.remember">自动登录</a-checkbox>
                  <div style="font-size: 14px; cursor: pointer; color: #3875f6;" @click="actionPassword">忘记密码</div>
                </div>
              </a-form-item>
              <a-form-item>
                <a-button style="width: 100%;" size="large" type="primary" html-type="submit" :loading="loading">登录</a-button>
              </a-form-item>
            </a-form>

            <CommonAgreementTips ref="CommonAgreementTips" v-model:value="isAgree"/>
          </div>
        </div>
      </div>
    </div>
    <!--中台登录-->
    <div v-else style="width: 100%; height: 100%">
      <div class="role_content" v-if="!selectRole">
<!--        <div style="color: white;font-size: 12px; font-weight: bold; padding-top: 18px;">携航CarryGlobal</div>-->
        <div class="role_title">携航货运平台</div>
        <div style="width: 525px; margin: 0 auto">
          <a-space :size="[8, 16]" wrap>
            <template v-for="item in roleItems" :key="item.key">
              <div class="role_item" @click="onSelectedRole(item)">
                <a-avatar :size="15" :src="item.icon"/>
                {{item.title}}</div>
            </template>
          </a-space>
        </div>
      </div>
      <div class="content" v-else>
        <!--中台登录页面-->
        <div style="height: 100%; display: flex; align-items: center;">
          <div class="login_box">
            <div class="logo"></div>
            <div style="height: 100%; flex: 1; padding: 20px 40px; font-size: 12px; color: #222; position: relative;">
              <div style="position: absolute; top: 2px; right: 2px; cursor: pointer;">
                <a-image :preview="false" :width="27" :src="icon_code"/>
              </div>
              <a-tabs v-model:activeKey="loginType" centered @change="tabChanged">
                <a-tab-pane key="password" tab="密码登录">
                  <a-form :model="passwordFormInfo" autocomplete="off" @finish="passwordOnFinish">
                    <a-form-item name="username" :rules="[{ required: true, message: '请输入账户名'}]">
                      <a-input v-model:value="passwordFormInfo.username" placeholder="请输入账户名">
                        <template #prefix><UserOutlined/></template>
                      </a-input>
                    </a-form-item>
                    <a-form-item name="password" :rules="[{ required: true, message: '请输入密码' }]">
                      <a-input-password v-model:value="passwordFormInfo.password" placeholder="请输入密码">
                        <template #prefix><LockOutlined/></template>
                      </a-input-password>
                    </a-form-item>
                    <a-form-item name="remember">
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <a-checkbox v-model:checked="passwordFormInfo.remember">自动登录</a-checkbox>
                        <div style="font-size: 14px; cursor: pointer; color: #3875f6;" @click="actionPassword">忘记密码</div>
                      </div>
                    </a-form-item>
                    <a-form-item>
                      <a-button style="width: 100%;" size="large" type="primary" html-type="submit" :loading="loading">登录</a-button>
                    </a-form-item>
                  </a-form>
                </a-tab-pane>
                <a-tab-pane key="code" tab="验证码登录">
                  <a-form :model="codeFormInfo" autocomplete="off" @finish="codeOnFinish">
                    <a-form-item name="Phone" :rules="[
                      { required: true, message: '请输入手机号'},
                      { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,message: '请输入正确的手机号'}]">
                      <a-input v-model:value="codeFormInfo.Phone" placeholder="请输入手机号">
                        <template #prefix><MobileOutlined/></template>
                        <template #addonBefore>
                          <a-select style="width: 90px" v-model:value="codeFormInfo.Country">
                            <a-select-option value="+86">+86</a-select-option>
                            <a-select-option value="+01">+01</a-select-option>
                          </a-select>
                        </template>
                      </a-input>
                    </a-form-item>
                    <div style="display: flex">
                      <a-form-item style="flex: 1" name="VerificationCode" :rules="[{ required: true, message: '请输入验证码' }]">
                        <a-input v-model:value="codeFormInfo.VerificationCode" placeholder="请输入验证码">
                          <template #prefix><CodeOutlined/></template>
                        </a-input>
                      </a-form-item>
                      <CommonVerificationCodeButton :phone="codeFormInfo.Phone"/>
                    </div>
                    <a-form-item>
                      <a-button style="width: 100%;" size="large" type="primary" html-type="submit" :loading="loading">登录</a-button>
                    </a-form-item>
                  </a-form>
                </a-tab-pane>
              </a-tabs>

              <CommonAgreementTips ref="CommonAgreementTips" v-model:value="isAgree"/>
              <!--其他登录方式-->
              <div style="margin-top: 14px;">
                <div style="display: flex; flex-direction: row; align-items: center;">
                  <div style="background: rgba(56,117,246,0.3); height: 1px; flex: 1"></div>
                  <div style="margin: 0 7px;">其他登录方式</div>
                  <div style="background: rgba(56,117,246,0.3); height: 1px; flex: 1"></div>
                </div>
                <div style="display: flex; justify-content: center; margin-top: 15px; margin-bottom: 20px;">
                  <a-image :preview="false" :width="20" :src="icon_wx"/>
                  <div style="width: 10px;"></div>
                  <a-image :preview="false" :width="20" :src="icon_al"/>
                </div>
              </div>
              <!--注册入口-->
              <div style="text-align: center;">没有账号？<span class="custom_span" @click="actionRegister">立即注册</span></div>
            </div>
          </div>
        </div>
        <!--底部版权区域-->
      </div>
    </div>
    <!--底部版权区域-->
    <div class="footer_box">
      <div>{{version}}</div>
      <div>Copyright<CopyrightOutlined style="margin: 0 5px"/>2022 携航Carryglobal</div>
      <a-space style="margin-top: 5px;">
        <a-image :width="18" :height="18" :preview="false" :src="icon_national" @click="show=true"></a-image>
        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=31011702889425"> <span>沪公网安备 31011702889425号</span></a>
        <a href="https://beian.miit.gov.cn/?spm=5176.29125882.J_9220772140.60.30fb2868kZGWVX#/Integrated/index"><span>{{projectScene == 2 ? '沪ICP备20220278645号-8' : '沪ICP备20220278645号-4'}}</span></a>
      </a-space>
    </div>
    <!--营业执照-->
    <a-modal v-model:open="show" :footer="null">
      <a-image :src="businessLicenseImg" :preview="false"/>
    </a-modal>
  </div>
</template>

<script>

import { UserOutlined, LockOutlined , MobileOutlined, CodeOutlined, CopyrightOutlined} from '@ant-design/icons-vue';

import {Home_SubmitLogin,Home_PhoneLogin, Home_GetOperatorInfo, Home_GetOperatorMenuList} from '@/api/Base_Manage/Home'
import {message} from "ant-design-vue";
import {mapActions} from 'vuex'
import CommonVerificationCodeButton from '@/components/common/CommonVerificationCodeButton'
import CommonAgreementTips from '@/components/common/CommonAgreementTips'
import md5 from 'js-md5'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "index",  // 登录页面  15528066347
  components:{
    UserOutlined,
    LockOutlined,
    MobileOutlined,
    CodeOutlined,
    CopyrightOutlined,
    CommonVerificationCodeButton,
    CommonAgreementTips
  },
  computed:{
    getTitleOfCodeButton(){
      return this.count > 0 ? `${this.count}s重新获取` : '获取验证码'
    }
  },
  data(){
    return {
      show:false,
      icon_national:require('@/assets/icon_national.png'),
      businessLicenseImg:require('@/assets/business_license.jpg'),
      logo:require('@/assets/logo.png'),
      icon_code:require('@/assets/imgs/login/login_code_icon.png'),
      icon_wx:require('@/assets/imgs/login/login_type_wx_icon.png'),
      icon_al:require('@/assets/imgs/login/login_type_ali_icon.png'),
      loginType:'password',  //  password--密码登录 / code--验证码登录,
      passwordFormInfo:{
        username:'',
        password:'',
        remember: false,
      },
      codeFormInfo:{
        Country:'+86',
        Phone:'',
        VerificationCode:'',
      },
      isAgree:false, //  是否同意
      loading: false, //  判断是否为登录中
      projectScene: 0,  //  0:开发环境 1:用户端 2:管理端
      roleItems:[
        {
          key:'1',
          title:'我是服务商',
          icon:require('@/assets/imgs/login/login_role_Service.png'),
        },
        {
          key:'2',
          title:'我是供应商',
          icon:require('@/assets/imgs/login/login_role_supplier.png'),
        },
        {
          key:'3',
          title:'我是企业用户',
          icon:require('@/assets/imgs/login/login_role_enterprise.png'),
        },
        {
          key:'4',
          title:'我是个人用户',
          icon:require('@/assets/imgs/login/login_role_person.png'),
        },
      ],
      selectRole:null, //  表示没有选择角色
      version:null
    }
  },
  mounted() {
    //  版本号
    this.version = require('../../../package.json').version

    //  获取当前项目场景
    this.projectScene = this.$store.getters['projectScene']
    // this.projectScene = 2
    this.cleanAllCacheData()
    this.getBaseConfigData()
    //  超级管理员:15900000000--123123
    let username = localStorage.getItem('login_username')
    if(username) {
      this.passwordFormInfo.username = username
      this.passwordFormInfo.password = localStorage.getItem('login_password')
      this.passwordFormInfo.remember = true
    } else {

      if(this.projectScene == 0){
        this.passwordFormInfo.username = '18701852886'
        this.passwordFormInfo.password = '123456'
      }else {
        this.passwordFormInfo.username = null
        this.passwordFormInfo.password = null
        this.passwordFormInfo.remember = false
      }
    }

    let Phone = localStorage.getItem('login_phone')

    if(Phone){
      this.codeFormInfo.Phone = Phone
    } else {
      this.codeFormInfo.Phone = ''
    }
  },
  methods:{
    ...mapActions(['setToken','setMenuList','delToken','delServertypes']),
    tabChanged(event){
      if(event == 'code'){
        // this.formInfo.Phone = '15528066347'
      }
    },
    //  选择角色
    onSelectedRole(item){
      this.selectRole = item
    },
    //  表单数据验证通过
    passwordOnFinish(){
      if(!this.isAgree){
        this.$refs.CommonAgreementTips.show()
        return
      }
      this.loading = true
      // eslint-disable-next-line no-empty
      let that =this
      if(this.passwordFormInfo.remember) {
        localStorage.setItem('login_username', this.passwordFormInfo.username)
        localStorage.setItem('login_password', this.passwordFormInfo.password)
        localStorage.setItem('rememberMe', '1')
      }
      else {
        localStorage.removeItem('login_username')
        localStorage.removeItem('login_password')
        localStorage.removeItem('rememberMe')
      }

      let tmpParmeters = {
        username: this.passwordFormInfo.username,
        password: md5(this.passwordFormInfo.password)
      }

      this.$post(Home_SubmitLogin,tmpParmeters).then((response)=>{
        if(response.Success){
          that.configUserData(response.Data)
        }else {
          that.loading = false
          message.error(response.Msg)
        }
      })
    },
    codeOnFinish(){
      if(!this.isAgree){
        this.$refs.CommonAgreementTips.show()
        return
      }
      this.loading = true
      // eslint-disable-next-line no-empty
      let that =this
      //  验证码登录
      this.$post(Home_PhoneLogin,this.codeFormInfo).then((response)=>{
        if(response.Success){
          that.configUserData(response.Data)
        } else {
          that.loading = false
          message.error(response.Msg)
        }
      })
    },
    onFinish(){
      if(!this.isAgree){
        this.$refs.CommonAgreementTips.show()
        return
      }

      this.loading = true
      // eslint-disable-next-line no-empty
      let that =this
      if(this.loginType == 'password') {
        //  密码登录
        // 18638887100
        if(this.passwordFormInfo.remember) {
          localStorage.setItem('login_username', this.passwordFormInfo.username)
          localStorage.setItem('login_password', this.passwordFormInfo.password)
          localStorage.setItem('rememberMe', '1')
        }
        else {
          localStorage.removeItem('login_username')
          localStorage.removeItem('login_password')
          localStorage.removeItem('rememberMe')
        }

        let tmpParmeters = {
          username: this.passwordFormInfo.username,
          password: md5(this.passwordFormInfo.password)
        }

        this.$post(Home_SubmitLogin,tmpParmeters).then((response)=>{
          if(response.Success){
            that.configUserData(response.Data)
          }else {
            that.loading = false
            message.error(response.Msg)
          }
        })
      }
      else if (this.loginType == 'code') {
        if(this.formInfo.remember) {
          localStorage.setItem('login_phone', this.codeFormInfo.Phone)
        }
        else {
          localStorage.removeItem('login_phone')
        }

        //  验证码登录
        this.$post(Home_PhoneLogin,this.codeFormInfo).then((response)=>{
          if(response.Success){
            that.configUserData(response.Data)
          } else {
            that.loading = false
            message.error(response.Msg)
          }
        })
      }
    },
    configUserData(result){
      const Token = result.Token
      //  token 存储cookies
      this.$cookies.set('Token', Token, '1d')
      window.sessionStorage.setItem('token', Token)
      this.setToken(Token)
      this.getUserInfo()
    },
    //  获取用户信息
    getUserInfo(){
      let that = this
      this.$post(Home_GetOperatorInfo).then((response)=>{
        if(response.Success){
          const userInfo = response.Data
          const projectScene = this.$store.getters['projectScene']
          if(projectScene == 0){
            //  开发环境
          }else if(projectScene == 1){
            //  PC端
            if(userInfo.ServerInfo == null){
              message.error('该账号无权限登录')
              return;
            }
          }else if(projectScene == 2){
            //  大后台的环境只有管理员账号可以进入
            if(userInfo.u_usertype != 66){
              message.error('该账号无权限登录')
              return
            }
          }
          that.$store.commit('account/saveUserInfo', userInfo)
          that.getOperatorMenuList()
        }
        else {
          that.loading = false
          message.error(response.Msg)
        }
      })
    },
    //  获取项目的导航栏信息
    getOperatorMenuList(){
      let that = this
      this.$post(Home_GetOperatorMenuList).then((response)=>{
        if(response.Success){
          const menuList = response.Data.sort((first,second)=>first.Sort - second.Sort)
          //  此次先使用本地的路由列表信息
          localStorage.setItem('menuList', JSON.stringify(menuList))
          message.success('登录成功',1.5).then(()=>{
            that.loading = false
            that.$router.push('/Index/index')
          })
        }
        else {
          that.loading = false
          message.error(response.Msg)
        }
      })
    },
    //  获取基础配置信息
    getBaseConfigData(){
    },
    //  需要把相关的本地缓存数据清除
    cleanAllCacheData(){
      this.delToken()
      this.delServertypes()
      this.$store.commit('account/removeUserInfo')
      localStorage.removeItem('cityList')
      localStorage.removeItem('menuList')
      localStorage.removeItem('roleType')
    },

    //  跳转到注册页面
    actionRegister(){
      this.$router.push('/account/register')
    },
    //  忘记密码
    actionPassword(){
      this.$router.push('/account/password')
    },
  }
}
</script>

<style lang="less" scoped>

span{
  //color: black;
}

.content{
  height: 100%;
  width: 100%;
  background: url("../../assets/imgs/login/login_bg.png") no-repeat;
  background-size: 100% 100%;
}

.role_content{
  height: 100%;
  width: 100%;
  background: url("../../assets/imgs/login/login_role_bg.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
}

.admin_content{
  height: 100%;
  width: 100%;
  background: url("../../assets/imgs/login/admin_login_bg.png") no-repeat;
  background-size: 100% 100%;
}

.admin_login_box{
  margin-right: 156px;
  width: 371px;
  height: 422px;
  background: rgba(255,255,255,0.3);
  border: 1px solid #FFFFFF;
  box-shadow: 0px 2px 7px 0px rgba(0,139,255,0.08);
  border-radius: 7px;
}

.login_box{
  margin: 0 auto;
  width: 742px;
  height: 436px;
  background-color: white;
  display: flex;
  border-radius: 7px;
  overflow: hidden;
  .logo{
    background: url("../../assets/imgs/login/login_logo.png") no-repeat;
    background-size: 100%;
    width: 50%; height: 100%;
  }
}

.footer_box{
  font-size: 14px;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 30px;
  color: #1677ff;
}
.ant-form-item{
  margin-bottom: 14px !important;
}
.custom_span{
  color: #3875F6;
  cursor: pointer;
}

.role_title{
  font-size: 45px;
  color: white;
  padding-top: 163px;
  margin-bottom: 50px;
  //font-family: MF KeSong (Noncommercial);
  font-weight: 400;
}

.role_item{
  width: 120px;
  height: 36px;
  background-color: #3875F6;
  border-radius: 4px;
  color: white;
  font-size: 10px;
  line-height: 36px;
  cursor: pointer;
}

</style>
