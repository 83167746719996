//  服务商相关
function serviceRouters() {
    return {
        "title": "服务商管理",
        "path": "/ServiceManage",
        "children": [
            {
                "title": "已认证服务商管理",
                "path": "/ServiceManage/Admin/ServiceProvider",
                "isActivate": false,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/ServiceManage/Admin/ServiceProvider.vue')
            },
            {
                "title": "入驻申请管理",
                "path": "/ServiceManage/Admin/ServiceApply",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/ServiceManage/Admin/ServiceApply.vue')
            },
            {
                "title": "企业认证管理",
                "path": "/ServiceManage/Admin/EnterpriseCertification",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/ServiceManage/Admin/EnterpriseCertification.vue')
            },
            {
                "title": "业务模式配置",
                "path": "/ServiceManage/Admin/ServiceMode",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/ServiceManage/Admin/ServiceMode.vue')
            },
            {
                "title": "入驻角色年费及保证金配置",
                "path": "/ServiceManage/Admin/RoleMargin",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/ServiceManage/Admin/RoleMargin.vue')
            },
            {
                "title": "拼单代理人管理",
                "path": "/ServiceManage/Admin/CollaboratingAgent",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/ServiceManage/Admin/CollaboratingAgent.vue')
            },
            {
                "title": "常客管理",
                "path": "/ServiceManage/Admin/SuperCustomerManage",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/UserManage/Admin/SuperCustomerManage.vue')
            },
            {
                "title": "航空公司指派操作签约人管理",
                "path": "/ServiceManage/Admin/SigningAgent",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/ServiceManage/Admin/SigningAgent.vue')
            },
            {
                "title": "服务商司机管理",
                "path": "/ServiceManage/Admin/ServiceDriver",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/ServiceManage/Admin/ServiceDriver.vue')
            },
            {
                "title": "入驻申请线下支付银行账户管理",
                "path": "/ServiceManage/Admin/BankAccountInformation",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/ServiceManage/Admin/BankAccountInformation.vue')
            },
            {
                "title": "个人信息",
                "path": "/ServiceManage/ServiceDetailInfo",
                "isActivate": true,
                "role": [1],
                component: () => import(/* webpackChunkName: "about" */ '@/views/ServiceManage/ServiceDetailInfo.vue')
            },
            {
                "title": "消息中心",
                "path": "/ServiceManage/MessageManage",
                "isActivate": true,
                "role": [1],
                component: () => import(/* webpackChunkName: "about" */ '@/views/ServiceManage/MessageManage.vue')
            },
            {
                "title": "企业认证信息",
                "path": "/ServiceManage/ServiceEnterpriseInfo",
                "isActivate": true,
                "role": [1],
                component: () => import(/* webpackChunkName: "about" */ '@/views/ServiceManage/ServiceEnterpriseInfo.vue')
            },
            {
                "title": "入驻信息",
                "path": "/ServiceManage/ServiceApplyInfo",
                "isActivate": true,
                "role": [1],
                component: () => import(/* webpackChunkName: "about" */ '@/views/ServiceManage/ServiceApplyInfo.vue')
            },
            {
                "title": "司机管理",
                "path": "/ServiceManage/DriverList",
                "isActivate": true,
                "role": [1],
                component: () => import(/* webpackChunkName: "about" */ '@/views/ServiceManage/DriverList.vue')
            },
            {
                "title": "网点管理",
                "path": "/ServiceManage/OutletsList",
                "isActivate": true,
                "role": [1],
                component: () => import(/* webpackChunkName: "about" */ '@/views/ServiceManage/OutletsList.vue')
            },
            {
                "title": "客户管理",
                "path": "/ServiceManage/CustomerManage",
                "isActivate": true,
                "role": [1],
                component: () => import(/* webpackChunkName: "about" */ '@/views/ServiceManage/CustomerManage.vue')
            },
            {
                "title": "保证金管理",
                "path": "/ServiceManage/EarnestMoney",
                "isActivate": true,
                "role": [1],
                component: () => import(/* webpackChunkName: "about" */ '@/views/ServiceManage/EarnestMoney.vue')
            },
        ]
    }
}

export default serviceRouters;
