export default {
    namespaced: true,
    state: {
        permissions: null,
        userInfo:null,
    },
    getters: {
        permissions: state=>{
            return state.permissions
        },
        userInfo: state=>{
            if (!state.userInfo) {
                try {
                    const userInfoJson = localStorage.getItem('_userInfo')
                    state.userInfo = JSON.parse(userInfoJson)
                } catch (e) {
                    console.error(e)
                }
            }
            return state.userInfo
        }
    },
    mutations: {    //commit
        savePermission(state, permissions) {
            state.permissions = permissions
        },
        saveUserInfo(state, userInfo){
            state.userInfo = userInfo
            localStorage.setItem('_userInfo', JSON.stringify(userInfo))
        },
        removeUserInfo(state){
            state.userInfo = null
            localStorage.removeItem('_userInfo')
        }
    },
    actions: {  // dispatch
        savePermission(context, permissions){
            context.commit('permissions', permissions)
        },
        saveUserInfo(context, userInfo){
            context.commit('userInfo', userInfo)
        },
    },
}
