<template>
  <a-button style="margin-left: 10px; display: inline-block;" @click="getVerificationCode" :disabled="count > 0 ? true : false">{{getTitleOfCodeButton}}</a-button>
</template>

<script>

import {message} from "ant-design-vue";
import {sys_tool_GetSmsVerificationCode} from "@/api/Business/sys_tool";

export default {
  name: "CommonVerificationCodeButton", //  获取验证码按钮
  props:{
    phone: String
  },
  data(){
    return {
      timer:null,     //  定时器，用来获取验证码使用
      count:0,        //  计数标记
    }
  },
  computed:{
    getTitleOfCodeButton(){
      return this.count > 0 ? `${this.count}s重新获取` : '获取验证码'
    }
  },
  methods:{
    getVerificationCode(){
      if(this.phone == null || this.phone.length == 0){
        message.error('手机号不能为空')
        return
      }
      let rule = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if(this.phone.length != 11 || !rule.test(this.phone)){
        message.error('错误的手机号')
        return
      }

      let that = this
      if(!this.timer) {
        this.count = 60
        this.timer = setInterval(()=>{
          if(that.count > 0 && that.count <= 60) {
            that.count--;
          }
          else {
            clearInterval(that.timer)
            that.timer = null
          }
        }, 1000)
      }
      this.$get(sys_tool_GetSmsVerificationCode,{'phone':this.phone}).then((response)=>{
        console.log('response', response)
        message.success('验证码已发送成功，请注意查看')
      })
    }
  }
}
</script>

<style scoped>

</style>
