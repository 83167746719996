<template>
  <a-modal v-model:open="visible" title="用户协议和隐私政策" width="1000px">
    <template #footer>
      <a-button type="primary" @click="onSubmit">同意并继续</a-button>
    </template>
    <div>
      欢迎注册成为携航用户! 在您注册过程中，您需要完成我们的注册流程并通过点击同意的形式在线签署以下协议，
      请您务必仔细阅读、充分理解协议中的条款内容后再点击同意(尤其是以粗体或下划线标识的条款，
      因为这些条款可能会明确您应履行的义务或对您的权利有所限制)。
      [请您注意] 如果您不意以下协议全部或任何条款约定，请您停止注册。
      您停止注册后将仅可以浏览我们的商品信息但无法享受我们的产品或服务。
      如您按照注册流程提示填写信息，阅读并点击同意上述协议且完成全部注册流程后，
      即表示您已充分阅读、理解并接受协议的全部内容，并表明您同意我们可以依据协议内容来处理您的个人信息，
      并同意我们将您的订单信息共享给为完成此订单所必须的第三方合作方
      （详情查看<span class="custom_span">《订单共享与安全》</span>)。
      如您对以下协议内容有任何疑问，您可随时通过《携航基本功能隐私政策》中的联系方式联系我们。
      如您在使用我们的产品或服务中与其他用户发生争议的，依您与其他用户达成的协议处理
    </div>
  </a-modal>
</template>

<script>
export default {
  name: "PopupAgreement", //  用户相关协议
  data() {
    return {
      visible: false,
    }
  },
  methods:{
    onShow(){
      this.visible = true
    },
    onSubmit(){
      this.visible = false
      this.$emit('callback')
    }
  }
}
</script>

<style scoped>
.custom_span{
  color: #3875F6;
  cursor: pointer;
}
</style>
