import axios from 'axios'
import {message} from "ant-design-vue";

// let cancel
// const promiseArr = {}
// eslint-disable-next-line
const CancelToken = axios.CancelToken

//  设置请求的api请求baseURL
// axios.defaults.baseURL = "https://www.carryglobal.net"
axios.defaults.baseURL = "https://test.carryglobal.net"

//  设置请求headers
axios.defaults.headers = {'Content-Type': 'application/json; charset=utf-8;'}
//  设置请求的超时时间
axios.defaults.timeout = 1000 * 30
// 请求拦截器
// axios.interceptors.request.use((config) => {
//     // 发起请求时，取消掉当前正在进行的相同请求
//     if (promiseArr[config.url]) {
//         promiseArr[config.url]('操作取消')
//         promiseArr[config.url] = cancel
//     } else {
//         promiseArr[config.url] = cancel
//     }
//     return config
// }, (error) => {
//     Promise.reject(error)
// })
// 响应拦截器即异常处理
axios.interceptors.response.use((response) => {
    const resCode = response.data.ErrorCode
    if (resCode === 0 || resCode === 1000) {
        return Promise.resolve(response.data)
    }
    return Promise.reject(response.data)
}, (err) => {
    console.log("err", err)
    console.log("err.response", err.response)
    const newErr = {
        code: 0,
        Msg: '请求超时，请稍后再试',
        Success: false
    }
    if (err && err.response) {
        newErr.code = err.response.code
        switch (err.response.code) {
            case 400:
                newErr.Msg = '错误请求'
                break
            case 401:
                newErr.Msg = '未授权，请重新登录'
                break
            case 403:
                newErr.Msg = '拒绝访问'
                break
            case 404:
                newErr.Msg = '请求错误,未找到该资源'
                break
            case 405:
                newErr.Msg = '请求方法未允许'
                break
            case 408:
                newErr.Msg = '请求超时'
                break
            case 500:
                newErr.Msg = '服务器端出错'
                break
            case 501:
                newErr.Msg = '网络未实现'
                break
            case 502:
                newErr.Msg = '网络错误'
                break
            case 503:
                newErr.Msg = '服务不可用'
                break
            case 504:
                newErr.Msg = '网络超时'
                break
            case 505:
                newErr.Msg = 'http版本不支持该请求'
                break
            case 1001:
                // this.$cookies.remove('login_token')
                // this.$cookies.remove('login_id')
                newErr.Msg = '非法的token'
                break
            default:
                newErr.Msg = err.message
        }
    }
    return Promise.resolve(newErr)
    // return Promise.reject(newErr)
})

//  Post请求
export function post(url, param) {
    let tmpParamer = {}
    if(Array.isArray(param)) {
        tmpParamer = param
    }
    else {
        for (const key in param) {
            const tmpValue = param[key]
            if (tmpValue !== null && tmpValue != undefined) {
                tmpParamer[key] = tmpValue
            }
        }
    }
    let token = this.$cookies.get('Token')
    let tmpHeaders = token ? { 'Authorization': 'Bearer ' + token,} : {}

    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url,
            data: JSON.stringify(tmpParamer),
            // cancelToken: new CancelToken((c) => {
            //     cancel = c
            // }),
            headers: tmpHeaders
        }).then((res) => {
            resolve(res)
        }).catch((err) => {
            if(err.ErrorCode == 401) {
                //  需要重新登录
                this.$router.push('/')
                return
            }
            message.error(err.Msg).then()
            resolve(null)
            reject(err)
        })
    })
}

//  Get请求
export function get(url, param = {}) {
    // eslint-disable-next-line no-unused-vars
    let tmpParamerString = ''
    for (const key in param) {
        const value = param[key]
        tmpParamerString += `${key}=${value}&`
    }
    if (tmpParamerString.length > 0) {
        url += `?${tmpParamerString}`
    }
    console.log('get请求的链接:', url)

    let token = this.$cookies.get('Token')
    let tmpHeaders = token ? { 'Authorization': 'Bearer ' + token,} : {}

    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url,
            // cancelToken: new CancelToken((c) => {
            //     cancel = c
            // }),
            headers: tmpHeaders
        }).then((res) => {
            resolve(res)
        }).catch((err) => {
            message.error(err.Msg).then()
            reject(err)
        })
    })
}

// //  获取用户授权值
// function getAuthorizationHeader(){
//     let token = this.$cookies.get('Token')
//     return token ? { 'Authorization': 'Bearer ' + token,} : {}
// }
