<template>
  <div>
    <a-tooltip v-model:open="showTips" trigger="none" placement="topLeft" title="请阅读并同意相关协议">
      <div style="display: flex; flex-direction: row; align-items: center; margin-top: 14px;">
        <a-checkbox v-model:checked="isAgree" @change="valueChanged"></a-checkbox>
        <div style="font-size: 12px; margin-left: 5px; color: #222">已阅读并同意《<span class="custom_span" @click="actionAgreement">用户协议</span>》和《<span class="custom_span" @click="actionAgreement">隐私协议</span>》</div>
      </div>
    </a-tooltip>
    <PopupAgreement ref="PopupAgreement" @callback="valueChanged"/>
  </div>
</template>

<script>
import PopupAgreement from '@/components/PopupAgreement'
export default {
  name: "CommonAgreementTips",  //  协议
  components:{
    PopupAgreement
  },
  props:{
    value:{
      type:Boolean,
      default:()=>{
        return false
      }
    },
  },
  data(){
    return {
      isAgree:false,
      showTips:false,
    }
  },
  methods:{
    show(){
      this.showTips = true
      let that = this
      setTimeout(()=>{
        that.showTips = false
      },1500)
    },
    //  查看相关协议
    actionAgreement(){
      this.$refs.PopupAgreement.onShow()
    },
    valueChanged(event){
      if(event == null){
        this.isAgree = true
        this.$emit('update:value', true)
      }else {
        this.$emit('update:value', event.target.checked)
      }
      this.$emit('change')
    },

  }
}
</script>

<style scoped>
.custom_span{
  color: #3875F6;
  cursor: pointer;
}
</style>
