function accountRouters() {
    return [
        {
            "title": "账号注册",
            "path": "/account/register",
            component: () => import(/* webpackChunkName: "about" */ '@/views/AccountManage/register.vue')
        },
        {
            "title": "个人注册",
            "path": "/account/person",
            component: () => import(/* webpackChunkName: "about" */ '@/views/AccountManage/person.vue')
        },
        {
            "title": "企业注册",
            "path": "/account/company",
            component: () => import(/* webpackChunkName: "about" */ '@/views/AccountManage/company.vue')
        },
        {
            "title": "找回密码",
            "path": "/account/password",
            component: () => import(/* webpackChunkName: "about" */ '@/views/AccountManage/password.vue')
        },
    ]
}

export default accountRouters;
