// 数据查询通用接口支持自定义参数数量(3)
export const sys_users_GetDataList = "/User/sys_users/GetDataList"
// 数据查询通用接口支持自定义参数数量(3)
export const sys_users_GetUserData = "/User/sys_users/GetUserData"
// 写入当前用户身份
export const sys_users_SetUserCurrentOwnID = "/User/sys_users/SetUserCurrentOwnID"
// 增加用户
export const sys_users_AddData = "/User/sys_users/AddData"
// 修改
export const sys_users_UpdaData = "/User/sys_users/UpdaData"
// 校验实名认证
export const sys_users_CheckIsRealName = "/User/sys_users/CheckIsRealName"
// 阿里实名认证
export const sys_users_AliRealNameAuthentication = "/User/sys_users/AliRealNameAuthentication"
// 阿里实名认证银行卡四要素
export const sys_users_AliRealBlankAuthentication = "/User/sys_users/AliRealBlankAuthentication"
// 获取用户OpenId
export const sys_users_GetOpenId = "/User/sys_users/GetOpenId"
